<div class="encabezado">
  <div>
    <div>
      <div>
        <h5 class="titulo1">{{ "pages.titles." + title | translate }}</h5>
      </div>
    </div>
  </div>
  <div class="">
    <div class="">
      <div class="row">
        <div class="col-8">
          <app-filter [ladata]="ladata" (filterChanged)="filterChanged($event)"></app-filter>
        </div>
        <div class="col-4" *ngIf="accountType == 'broker' && this.typeOrders != 'cancelled'">
          <div class="btn-izq">
            <button class="btn btn-hover" type="button" (click)="toggleForm()">
              {{ "buttons.add+" | translate }} <i class="icon-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="showAlertFilter">
      <div class="col-12">
        <div class="alert alert-warning alert-dismissible fade show" role="alert">
          <div *ngIf="showCountFilter > 0"> <span>{{ "filter.part1" | translate }} <strong>
                {{ showCountFilter }}
                {{ showCountFilter === 1 ? ("filter.part2_singular" | translate) : ("filter.part2_" | translate) }}
              </strong></span> {{ "filter.part3" | translate }} <span *ngIf="showAlertFilterAll">{{
              "filter.part4" | translate }} <strong> <a routerLink="/orders/all"> {{
                  "filter.part5" | translate }}</a> </strong></span></div>
          <div *ngIf="showCountFilter == 0 "><span> <strong>{{ "filter.part6" | translate }}
                {{"filter.part2_" | translate }}</strong>{{ "filter.part3" | translate }} </span> <span
              *ngIf="showAlertFilterAll"> {{
              "filter.part4" | translate }} <strong> <a routerLink="/orders/all"> {{
                  "filter.part5" | translate }}</a> </strong></span>
          </div>

        </div>
      </div>
    </div>

    <div class="tabla">
      <table mat-table class="full-width-table" matSort aria-label="Brands" multiTemplateDataRows
        [dataSource]="dataSource">
        <!-- (matSortChange)="onSortChange($event)"> -->
        <!-- broker_reference Column --> [ngClass]="getBadgeColorClient(row.client?.type)"
        <ng-container matColumnDef="broker_reference">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('broker_reference')">
            {{ "tables.columns.broker_reference" | translate }} <i *ngIf="sortColumn == 'broker_reference'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.broker_reference }}</td>
        </ng-container>

        <!-- supplier_reference Column -->
        <ng-container matColumnDef="supplier_reference">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('supplier_reference')">
            {{ "tables.columns.supplier_reference" | translate }} <i *ngIf="sortColumn == 'supplier_reference'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">{{ row?.supplier_reference }}</td>
        </ng-container>

        <!-- broker_agent Column -->
        <ng-container matColumnDef="broker_agent">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('broker_agent')">
            {{ "tables.columns.broker_agent" | translate }} <i *ngIf="sortColumn == 'broker_agent'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row?.broker_agent?.name + " " + row?.broker_agent?.lastname }}
          </td>
        </ng-container>

        <!-- supplier_agent Column -->
        <ng-container matColumnDef="supplier_agent">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('supplier_agent')">
            {{ "tables.columns.supplier_agent" | translate }} <i *ngIf="sortColumn == 'supplier_agent'"
              [ngClass]="getDirection()"></i>
          </th>

          <td mat-cell *matCellDef="let row">
            <span *ngIf="row?.supplier_agent?.name">
              {{
              row?.supplier_agent?.name + " " + row?.supplier_agent?.lastname
              }}</span>
          </td>
        </ng-container>

        <!-- broker Column -->
        <ng-container matColumnDef="broker">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('broker')">
            {{ "tables.columns.broker" | translate }} <i *ngIf="sortColumn == 'broker'" [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.broker?.name"> {{ row?.broker?.name }}</span>

              <div class="d-flex flex-column" *ngIf="expandedRow ? expandedRow.reference == row.reference : false">
                <span *ngIf="row?.broker_agent?.name" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.broker_agent?.name }}
                  {{ row?.broker_agent?.lastname }}</span>
                <span *ngIf="row?.broker_reference" class="secondary-text">
                  <i class="icon-barcode"></i> {{ row?.broker_reference }}</span>
              </div>


            </div>
          </td>
        </ng-container>

        <!-- Supplier Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('supplier')">
            {{ "tables.columns.supplier" | translate }} <i *ngIf="sortColumn == 'supplier'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.supplier?.name">
                {{ row?.supplier?.name }}</span>
              <div class="d-flex flex-column" *ngIf="expandedRow ? expandedRow.reference == row.reference : false">

                <span *ngIf="row?.supplier_agent?.name" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.supplier_agent?.name }}
                  {{ row?.supplier_agent?.lastname }}</span>
                <span *ngIf="row?.supplier_reference" class="secondary-text">
                  <i class="icon-barcode"></i> {{ row?.supplier_reference }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- vendor Column -->
        <ng-container matColumnDef="vendor">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('vendor')">
            {{ "tables.columns.vendor" | translate }} <i *ngIf="sortColumn == 'vendor'" [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.vendor?.name"> {{ row?.vendor?.name }}</span>

              <div class="d-flex flex-column" *ngIf="expandedRow ? expandedRow.reference == row.reference : false">
                <span *ngIf="row?.broker_agent?.agent" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.broker_agent?.name }}</span>
                <span *ngIf="row?.vendor_reference" class="secondary-text">
                  <i class="icon-barcode"></i> {{ row?.vendor_reference }}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- client Column -->
        <ng-container matColumnDef="client">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('client')">
            {{ "tables.columns.client" | translate }} <i *ngIf="sortColumn == 'client'" [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.client?.name">
                {{ row?.client?.name }} {{ row?.client?.lastname ? row?.client?.lastname : ""}}</span>

              <div class="d-flex flex-column" *ngIf="expandedRow ? expandedRow.reference == row.reference : false">
                <span *ngIf="row?.client?.phone" class="secondary-text">
                  {{ row?.client?.tax_id }}</span>

                <span *ngIf="row?.guarantee?.name"> {{ "forms.inputs.guarantee" | translate }} :
                  {{ row?.guarantee?.name }} {{ row?.guarantee?.lastname ? row?.guarantee?.lastname : ""}}</span>

                <span *ngIf="row?.guarantee?.phone" class="secondary-text">
                  {{ row?.guarantee?.tax_id }}</span>
              </div>

              <span *ngIf="row?.client?.name" class="badge text-badge-list"
                [ngClass]="getBadgeColorClient(row.client?.type)">{{
                "forms.inputs." + row.client?.type | translate
                }}</span>
            </div>
          </td>
        </ng-container>

        <!-- vehicle Column -->
        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('vehicle')">
            {{ "tables.columns.vehicle" | translate }} <i *ngIf="sortColumn == 'vehicle'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="column-list">
              <span *ngIf="row?.vehicle">{{ row?.vehicle_brand }} {{ row?.vehicle_model }} {{ row?.vehicle }}
                ({{row?.vehicle_model_year}})</span>
              <span *ngIf="row?.vehicle_status" class="badge text-badge-list"
                [ngClass]="getBadgeColorVehicle(row.vehicle_status)">{{ "badge."
                + row?.vehicle_status | translate }}</span>

              <div class="d-flex flex-column" *ngIf="expandedRow ? expandedRow.reference == row.reference : false">
                <span *ngIf="row?.colour" class="secondary-text">
                  <i class="icon-brush2"></i> {{ row?.colour }}</span>
                <span class="area-table"></span>
                <span *ngIf="row?.engine" class="secondary-text">
                  {{ row?.engine }}</span>
                <span *ngIf="row?.vin" class="secondary-text">
                  {{ row?.vin }}</span>
                <span *ngIf="row?.duration" class="secondary-text">
                  {{ row?.duration }}
                  {{ "tables.months" | translate }}</span>
                <span *ngIf="row?.total_kms" class="secondary-text">
                  {{ row?.total_kms }} kms</span>
                <span *ngIf="row?.license_plate" class="secondary-text">
                  {{ row?.license_plate }}</span>
                <span *ngIf="row?.monthly_fee_total" class="secondary-text">
                  <i class="icon-coin-euro"> </i>
                  {{ row?.monthly_fee_total }} Є/{{ "tables.month" | translate}}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- updated_at Column -->
        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('updated_at')">
            {{ "tables.columns.updated_at" | translate }} <i *ngIf="sortColumn == 'updated_at'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="d-flex flex-column"
              *ngIf="expandedRow ? expandedRow.reference == row.reference : false ; else notExpanded">
              <div class="column-list">
                <span *ngIf="row?.updated_at">
                  {{ row?.updated_at }}
                </span>
                <span *ngIf="row?.updated_at" class="secondary-text">
                  <i class="icon-user"></i>
                  {{ row?.updated_by }}
                </span>
              </div>
            </div>
            <ng-template #notExpanded>
              <div class="column-list">
                <span *ngIf="row?.updated_at">
                  {{ row?.updated_at | date: 'yyyy-MM-dd' }}
                </span>
                <span *ngIf="row?.updated_at" class="secondary-text">
                  <i class="icon-user"></i>
                  {{ row?.updated_by }}
                </span>
              </div>


            </ng-template>

          </td>
        </ng-container>

        <!-- stage_updated_at Column -->
        <ng-container matColumnDef="stage_updated_at">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('stage_updated_at')">
            {{ "tables.columns.stage_updated_at" | translate }} <i *ngIf="sortColumn == 'stage_updated_at'"
              [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">

            <div class="d-flex flex-column"
              *ngIf="expandedRow ? expandedRow.reference == row.reference : false; else notExpanded1">
              <div class="column-list">
                <span *ngIf="row?.stage_updated_at">
                  {{ row?.stage_updated_at }}</span>
                <span *ngIf="row?.stage_updated_by" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.stage_updated_by }}</span>
              </div>
            </div>
            <ng-template #notExpanded1>
              <div class="column-list">
                <span *ngIf="row?.stage_updated_at">
                  {{ row?.stage_updated_at | date: 'yyyy-MM-dd' }}</span>
                <span *ngIf="row?.stage_updated_by" class="secondary-text">
                  <i class="icon-user"></i> {{ row?.stage_updated_by }}</span>
              </div>

            </ng-template>


          </td>
        </ng-container>

        <!-- stage Column -->
        <ng-container matColumnDef="stage">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla" (click)="onSortChange2('stage')">
            {{ "tables.columns.stage" | translate }} <i *ngIf="sortColumn == 'stage'" [ngClass]="getDirection()"></i>
          </th>
          <td mat-cell *matCellDef="let row">
            <span class="badge text-badge-list" [ngClass]="getBadgeColor(row.stage,row.require_action,row.priority)">{{
              "badge." + row?.stage | translate
              }}</span>
          </td>
        </ng-container>

        <!-- actions Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="cabecera-tabla">
            {{ "tables.columns.actions" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <i class="icon-pencil text-success icon-actions" (click)="getOrder(row.reference)" aria-hidden="true"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="toggleRow(row)"
          [ngClass]="{'tr-selected': expandedRow?.reference === row.reference}"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->

      </table>

      <mat-paginator [length]="paginator.length ? paginator.length : 25" [pageSize]="paginator.pageSize"
        [pageSizeOptions]="paginator.pageSizeOptions" (page)="onPageChange($event)">
      </mat-paginator>
    </div>
  </div>

  <div class="formulario-derecha-container" [ngClass]="{ 'mostrar-formulario': showForm }">
    <app-form-add-order *ngIf="showForm" [orderData]="orderData" [tab]="tab" (backButtonClick)="cerrarFormulario()"
      (addOrder)="refrescar()" (emitOpenClientForm)="openClientForm()" (emitOpenAssigneeForm)="openAssigneeForm($event)"
      (emitCloseClientForm)="closeClientForm()" (emitCloseAssigneeForm)="closeAssigneeForm()"
      [document_buttons_chico]="document_buttons_chico" [ladata]="ladata"></app-form-add-order>
  </div>

  <div class="formulario-sub-derecha-container" [ngClass]="{'mostrar-formulario': showAddClientForm }">
    <app-form-add-client *ngIf="showAddClientForm" (backButtonClick)="cerrarClienteFormulario()"
      (addClient)="refreshClients()">
    </app-form-add-client>
  </div>

  <div class="formulario-sub-derecha-container" [ngClass]="{'mostrar-formulario': showAssigneeForm }">
    <app-form-show-assignee *ngIf="showAssigneeForm" (backButtonClick)="cerrarAssigneeFormulario()"
      [assignee]="assignee" (addClient)="refreshClients()">
    </app-form-show-assignee>
  </div>

  <div class="overlay" *ngIf="showForm" (click)="cerrarFormulario()"></div>
</div>
