<div class="d-flex">
  <div class="card w-100 form-right">
    <div class="back">
      <h2 *ngIf="!automationData">{{ "forms.names.addAutomation" | translate }}</h2>
      <h2 *ngIf="automationData">{{ "forms.names.editAutomation" | translate }}</h2>
      <i class="icon-cross2" (click)="emitBackButtonClick()"></i>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form">
          <form [formGroup]="form">
            <div>
              <div class="form-section">
                <div class="mb-3">
                  <label for="rule_name" class="form-label"
                    >{{ "forms.inputs.rule_name" | translate }}
                    <span class="required">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    formControlName="name"
                    required
                  />
                </div>
              </div>

              <div class="form-section">
                <div class="mb-3">
                  <label for="rule_descr" class="form-label"
                    >{{ "forms.inputs.rule_descr" | translate }}
                    <span class="required">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="description"
                    formControlName="description"
                    required
                  />
                </div>
              </div>

              <div class="form-section">
                <div class="mb-3">
                  <label for="event" class="form-label"
                    >{{ "forms.inputs.event" | translate }}
                    <span class="required">*</span></label
                  >
                  <select
                    id="event_id"
                    class="form-control"
                    formControlName="event_id"
                  >
                    <option value="0" selected>
                      {{ "automation.select_event" | translate }}
                    </option>
                    <option *ngFor="let event of events" [value]="event.key">
                      <!-- {{ event.name }} -->
                      {{ "automation.event." + event.key| translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-section">
                <label for="condition" class="form-label"
                  >{{ "forms.inputs.condition" | translate }}
                </label>

                <div class="conditions">
                  <div class="condition">
                    <div
                      *ngFor="let condition_ of arrayConditions; let i = index"
                      class="conditionItem"
                    >
                      <select
                        (change)="updateCondition(i, 'condition', $event)"  >
                        <option value="0">
                          {{ "automation.select_condition" | translate }}
                        </option>
                        <option
                          *ngFor="let condition of conditions"
                          [value]="condition.key"
                          [selected]="condition.key === condition_.condition"
                        >
                          <!-- {{ condition.name }} -->
                          {{ "automation.conditions." + condition.key| translate }}
                        </option>
                      </select>
                      <select (change)="updateCondition(i, 'operator', $event)">
                        <option value="0">
                          {{ "automation.select" | translate }}
                        </option>
                        <option value="=" [selected]="condition_.operator == '='">
                          {{ "automation.same" | translate }}
                        </option>
                        <option value="!=" [selected]="condition_.operator == '!='">
                          {{ "automation.diferent" | translate }}
                        </option>
                      </select>
                      <input
                        type="text"
                        placeholder="Valor"
                        value="{{ condition_.value }}"
                        (input)="updateCondition(i, 'value', $event)"
                      />
                      <button (click)="removeCondition(i)">X</button>
                    </div>
                    <button class="addConditionButton" (click)="addCondition()">
                      {{ "forms.buttons.addCondition" | translate }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <label for="action" class="form-label"
                  >{{ "forms.inputs.action" | translate }}
                </label>
                <div class="conditions">
                  <div class="condition">
                    <div
                      *ngFor="let action_ of arrayActions; let i = index"
                      class="conditionItem"
                    >
                      <select (change)="updateAction(i, 'action', $event)">
                        <option value="0">
                          {{ "automation.select_action" | translate }}
                        </option>
                        <option
                          *ngFor="let action of actions"
                          [value]="action.key"
                            [selected]="action.key === action_.action"
                        >
                          <!-- {{ action.name }} -->
                          {{ "automation.actions." + action.key| translate }}
                        </option>
                      </select>

                      <input
                        type="text"
                        placeholder="Valor"
                        (input)="updateAction(i, 'value', $event)"
                         value="{{ action_.value }}"
                      />
                      <button (click)="removeAction(i)">X</button>
                    </div>
                    <button class="addConditionButton" (click)="addAction()">
                      {{ "forms.buttons.addAction" | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="form-check require-action">
        <input #requireActionCheckbox class="form-check-input" type="checkbox" value="" id="flexCheckDefault" (change)="onCheckboxChange($event)">
        <label class="form-check-label" for="flexCheckDefault">
          {{ "forms.inputs.enabled" | translate }}
        </label>
     </div>
      <div class="div-form"></div>
      <div class="btn-send">
        <button
          type="submit"
          class="btn btn-primary btn-primary-form"
          (click)="opt === 'new' ? onSubmit() : onSubmitOrder()"
        >
          {{ "buttons.send" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
