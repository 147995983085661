<div class="document-buttons" [ngClass]="{ 'document-buttons-chico': document_buttons_chico }">
  <div *ngIf="!draft" id="btn-general" class="document-button" [ngClass]="activeFormTab == 'general' ? 'selected' : ''"
    (click)="emitchangeForm('general')">
    <button type="submit" class="btn-documents">
      <i class="icon-user"></i>
      {{ "forms.buttons.general" | translate }}
      <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
    </button>
  </div>
  <div id="btn-general" class="document-button" [ngClass]="activeFormTab == 'doc-order' ? 'selected' : ''"
    (click)="emitchangeForm('doc-order')">
    <button type="submit" class="btn-documents">
      <i class="icon-files"></i>
      {{ "forms.buttons.doc-order" | translate }}
      <span *ngIf="updatesTabs.includes('doc-order')" class="violet-dot"></span>
    </button>
  </div>

  <div id="btn-general" class="document-button" [ngClass]="activeFormTab == 'orders' ? 'selected' : ''"
    (click)="emitchangeForm('orders')">
    <button type="submit" class="btn-documents">
      <i class="icon-clipboard-empty"></i>
      {{ "forms.buttons.orders" | translate }}
      <span *ngIf="updatesTabs.includes('orders')" class="violet-dot"></span>
    </button>
  </div>

  <!--   <div>
    <div id="btn-vehicle" class="document-button" [ngClass]="activeFormTab == 'financial-data' ? 'selected' : ''"
      (click)="emitchangeForm('financial-data')">
      <button type="submit" class="btn-documents">
        <i class="icon-clipboard-user"></i>
        {{ "forms.buttons.financial-data" | translate }}
        <span *ngIf="updatesTabs.includes('financial-data')" class="violet-dot"></span>
      </button>
    </div>
  </div> -->

</div>

<div *ngIf="addOrderForm" class="document-buttons" [ngClass]="{ 'document-buttons-chico': document_buttons_chico }">
  <div *ngIf="!draft" id="btn-general" class="document-button" [ngClass]="activeFormTab == 'general' ? 'selected' : ''"
    (click)="emitchangeForm('general')">
    <button type="submit" class="btn-documents">
      <i class="icon-clipboard-empty"></i>
      {{ "forms.buttons.general" | translate }}
      <span *ngIf="updatesTabs.includes('general')" class="violet-dot"></span>
    </button>
  </div>
</div>
