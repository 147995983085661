import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatCommonModule,
  MatNativeDateModule,
} from '@angular/material/core';
import moment from 'moment';
import { ModelService } from '../../../../core/services/model/model.service';
import { AlertService } from '../../../../core/services/alert/alert.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { OrdersService } from '../../../../core/services/orders/orders.service';


const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-vehicle-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    CommonModule],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  templateUrl: './vehicle-form.component.html',
  styleUrl: './vehicle-form.component.css'
})
export class VehicleFormComponent {
  @Input() orderData?: any;
  @Input() ladata: any = [];
  @Output() emitDuplicate = new EventEmitter<boolean>();

  vehicleForm: FormGroup;
  models: any[] = [];
  brands: any[] = [];

  vehicle_model_id_ = '';

  dealershipData: any = [];
  constructor(private fb: FormBuilder, private modelService: ModelService, private alertService: AlertService,private orderService: OrdersService) {
    this.vehicleForm = this.fb.group({
      colour: ['', Validators.required],
      vehicle: ['', Validators.required],
      vehicle_brand_id: ['', Validators.required],
      vehicle_id: ['', Validators.required],
      vehicle_mileage: ['', Validators.required],
      vehicle_model_id: ['', Validators.required],
      vehicle_model_year: ['', Validators.required],
      vehicle_retail_price: ['', Validators.required],
      vehicle_registration_date: ['', Validators.required],
      vehicle_status: ['', Validators.required],
      vehicle_units: ['', Validators.required],
      license_plate: ['', Validators.required],
      vin: ['', Validators.required],
      vehicle_billing_date: ['', Validators.required],
      dealership_id: ['', Validators.required],
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes['ladata']) {
        this.brands = this.ladata.brands;
        this.dealershipData = this.ladata.dealershipData;
      }

      if (changes['orderData']) {
        if (this.orderData) {
          console.log("🚀 ~ VehicleFormComponent ~ ngOnChanges ~ this.orderData:", this.orderData)
          this.refrescarFormulario();
        }
      }
    }
  }


  refrescarFormulario() {
    this.updateFieldAccessibility();
    if (this.models.length === 0) {
      if (this.orderData.vehicle_brand_id) {
        this.onBrandChange(this.orderData.vehicle_brand_id);
      }
    }


    for (const key in this.orderData) {
      const control = this.vehicleForm.controls[key];
      const value = this.orderData[key];

      if (control) {
        switch (key) {
          case 'delivery_first_expected_date':
            control.setValue(value ? moment(value).format('DD/MM/YYYY') : '');
            break;
          default:
            control.setValue(value);
            break;
        }

      }
    }
    this.alertService.close();
  }

  async onBrandChange(value: any) {
    if (!value) return;
    var brand_reference: any = '';
    if (typeof value === 'string') {
      brand_reference = value;
    } else {
      brand_reference = value.target.value;
    }

    try {
      const data = await this.modelService.getDataByBrand(brand_reference).toPromise();
      this.models = data.data;
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  updateFieldAccessibility() {

    var rules = this.orderData?.rules;
    // Deshabilitar todos los campos por defecto
    for (const field in this.vehicleForm.controls) {
      if (this.vehicleForm.controls.hasOwnProperty(field)) {
        this.vehicleForm.get(field)?.disable();
      }
    }

    // Habilitar solo los campos con valor 'w' en las reglas
    for (const field in rules) {
      if (rules.hasOwnProperty(field) && rules[field] === 'w') {
        this.vehicleForm.get(field)?.enable();
      }
    }

  }
  checkUpdateForm(): boolean {
    if (this.vehicleForm.dirty) {
      return true;
    } else {
      return false;
    }
  }
  copyToClipboard(value: string, event: Event): void {
    // Convertir el valor a mayúsculas y eliminar acentos
    const transformedValue = this.removeAccents(value.toUpperCase());

    // Crear un textarea para copiar el valor
    const textarea = document.createElement('textarea');
    textarea.value = transformedValue;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Añadir la clase para el estado copiado
    const button = event.currentTarget as HTMLElement;
    button.classList.add('copied');

    // Eliminar la clase después de 1 segundo
    setTimeout(() => {
      button.classList.remove('copied');
    }, 1000);
  }

  // Función para eliminar acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  duplicate() {
    this.emitDuplicate.emit(true);
  }



}
