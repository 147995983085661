import { CommonModule, Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../../core/services/alert/alert.service';
import { LocalStorageService } from '../../../core/services/localStorage/local-storage.service';
import { ClientsService } from '../../../core/services/clients/clients.service';
import { FormAddOrderComponent } from '../../orders/form-add-order/form-add-order.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FormAddOrdersComponent } from './form-add-orders/form-add-orders.component';
import { DataService } from '../../../core/services/orders/data.service';
import { OrdersService } from '../../../core/services/orders/orders.service';
import { Title } from '@angular/platform-browser';
import { AccountsService } from '../../../core/services/accounts/accounts.service';
import { UsersService } from '../../../core/services/users/users.service';
import { BrandService } from '../../../core/services/brand/brand.service';
import { DocumentsTypeService } from '../../../core/services/documentType/documents-type.service';

interface AccountData {
  reference: string;
  name: string;
  type: string;
  tax_id: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  root_account: any; // ajusta según la estructura real de root_account si es necesario
  status: boolean;
}
interface ApiResponse {
  data: AccountData[];
  // Otros campos posibles como error, message, etc.
}

@Component({
  selector: 'app-form-clients-orders',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    FormAddOrdersComponent,
  ],
  templateUrl: './form-clients-orders.component.html',
  styleUrl: './form-clients-orders.component.css',
})
export class FormClientsOrdersComponent {
  @Input() client_reference: any;
  @Input() document_buttons_chico = false;
  @Input() client?: any;
  @Input() activeFormTab?: any;
  @Input() clientData?: any;
  @Input() orderData?: any;
  ladata: any = [];

  @Output() emitOpenOrderForm = new EventEmitter<boolean>();
  @Output() emitOpenOrderForm2 = new EventEmitter<boolean>();

  sortColumn = 'stage_updated_at';
  sortDirection = 'asc';

  expandedRow: any = null;

  showAddOrderForm = false;

  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    'broker_reference',
    /*   'supplier_reference',
    'broker_agent',
    'supplier_agent',
    'broker', */
    'supplier',
    'vendor',
    /* 'client', */
    'vehicle',
    'updated_at',
    /*   'stage_updated_at', */
    'stage',
    'actions',
  ];

  constructor(
    private router: Router,
    private alertService: AlertService,
    private clientsService: ClientsService,
    private dataService: DataService,
    private orderService: OrdersService,
    private location: Location,
    private titleService: Title,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    private accountService: AccountsService,
    private userService: UsersService,
    private brandService: BrandService,
    private documentTypeService: DocumentsTypeService
  ) {}

  ngOnInit(): void {
    this.fetchAllData();
    console.log("clientData", this.clientData)
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['ladata']) {
      console.log('Ladata actualizada desde el servicio:', this.ladata);
    }


    if (this.clientData) {

      this.titleService.setTitle(
        `Cliente ${this.clientData.name} ${
          this.clientData.lastname ? ` ${this.clientData.lastname}` : ''
        } - DBM`
      );
    }
  }

  getLaData(): void {
    this.dataService.getLadata;
  }

  getClientsOrders(reference: string): void {

    if (reference) {
       this.alertService.loading('swal_messages.please_wait');
      this.clientsService.getClientsOrders(reference).subscribe({
        next: (data) => {
          console.log('Ordenes del cliente:', data);
          this.dataSource.data = data.data;
           this.alertService.close();
          console.log(this.dataSource.data);
        },
        error: (err) => {
          console.error('Error al obtener las ordenes del cliente:', err);
        },
      });
    }
  }

  order_reference?: any;
  showForm = false;
  typeOrders: string = 'drafts';
  title = 'orders';

  getOrder(reference: string, stage: string): void {
    this.alertService.loading('swal_messages.please_wait');

    // Ajusta el valor de stage según el caso
    let adjustedStage;
    if (stage === 'draft') {
      adjustedStage = 'drafts';
    } else if (stage === 'sent' || stage === 'cancellation_requested') {
      adjustedStage = 'contracts';
    } else {
      adjustedStage = stage;
    }

    // Construye la nueva URL para la orden, usando adjustedStage
    const nuevaURL = `/orders/${adjustedStage}/${reference}`;

    // Redirige a la URL de orden utilizando el Router
    this.router.navigateByUrl(nuevaURL).then(() => {
      // Llama al servicio para obtener los datos de la orden
      this.order_reference = reference;
      this.orderService.searchOrder(this.order_reference).subscribe({
        next: (data) => {
          this.orderData = data.data;

          // Construye el título de la página con los datos de la orden
          let title = data.data.client?.name
            ? `${data.data.client.name} ${data.data.client.lastname || ''}`
            : data.data.broker.reference;

          // Establece el título de la página
          this.titleService.setTitle(
            `${this.translate.instant(
              'pages.titles.' + this.title
            )} ${title} - DBM`
          );

          // Muestra el formulario de la orden
          this.showForm = true;
          this.alertService.close();
        },
        error: () => {
          this.alertService.error('swal_messages.order_not_found');
        },
      });
    });
  }

  toggleAddOrderForm(): void {
    this.showAddOrderForm = !this.showAddOrderForm;
  }

  addOrdersComponent?: FormAddOrderComponent;

  openAddOrderForm() {
    this.document_buttons_chico = true;
    this.emitOpenOrderForm.emit();
  }

  openAddOrderForm2() {
    this.document_buttons_chico = true;
    this.emitOpenOrderForm2.emit(this.clientData.name);
    this.showAddOrderForm = true;
  }

  getBadgeColorClient(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      organization: 'text-organization',
      'self-employed': 'text-self-employed',
      individual: 'text-individual',
    };

    return statusColorMap[status] || 'text-individual';
  }

  getBadgeColorVehicle(status: string): string {
    const statusColorMap: { [key: string]: string } = {
      new: 'text-new',
      used: 'text-used',
    };
    return statusColorMap[status] || 'text-new';
  }

  getBadgeColor(
    stage: string,
    requiere_action: string,
    priority: string
  ): string {
    const statusColorMap: { [key: string]: string } = {
      draft: 'text-draft',
      canceled: 'text-canceled',
      false: 'text-no-action',
      '5': 'text-priority-5',
      '4': 'text-priority-4',
      '3': 'text-priority-3',
      '2': 'text-priority-2',
      '1': 'text-priority-1',
      '0': 'text-priority-0',
    };

    if (stage === 'draft') {
      return statusColorMap['draft'];
    }
    if (stage === 'canceled') {
      return statusColorMap['canceled'];
    }

    if (!requiere_action) {
      return statusColorMap['false'];
    }

    if (requiere_action) {
      // Ensure priority is one of the expected values
      const priorityColor = statusColorMap[priority];
      return priorityColor || 'text-priority-0';
    }

    // Default color if none of the conditions match
    return 'text-bg-primary';
  }

  /**********ladata****** */

  cargaladata: any = [];
  pageNumber: number = 1;
  pageSize: number = 100;
  searchParams: any = {};

  private fetchAllData() {
    Promise.all([
      this.getClientsOrders(this.client_reference),
      this.fetchData2(),
      this.fetchData3(),
      this.fetchData4(),
      this.fetchData5(),
      this.fetchData6(),
      this.fetchData7(),
      this.fetchData8(),
    ]).then(() => {
      this.ladata = this.cargaladata;
      this.dataService.setLadata(this.ladata);
      setTimeout(() => {
        this.alertService.close();
      }, 500);
    });
  }

/*   fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.alertService.loading('swal_messages.please_wait');
      this.orderService
        .getData(
          this.typeOrders,
          this.pageNumber,
          this.pageSize,
          this.searchParams,
          this.sortColumn,
          this.sortDirection
        )

        .subscribe({
          next: (data) => {
            this.dataSource.data = data.data;
            this.cargaladata.flag = true;
            this.pageNumber = data.meta.current_page;
            if (!this.localStorageService.getItem('per_page')) {
              this.localStorageService.setItem('per_page', data.meta.per_page);
              this.pageSize = data.meta.per_page;
            } else {
              this.pageSize = Number(
                this.localStorageService.getItem('per_page')
              );
            }

            resolve();
          },
          error: (error) => {
            this.alertService.error(error.error);
            reject(error);
          },
        });
    });
  } */
  fetchData2(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.accountService.getData().subscribe({
        next: (response: ApiResponse) => {
          if (!response || !response.data) {
            resolve();
            return;
          }
          const data: AccountData[] = response.data;
          this.cargaladata.vendorData = data.filter(
            (item) => item.type === 'vendor'
          );
          this.cargaladata.brokerData = data.filter(
            (item) => item.type === 'broker'
          );
          this.cargaladata.supplierData = data.filter(
            (item) => item.type === 'supplier'
          );
          this.cargaladata.workshopData = data.filter(
            (item) => item.type === 'workshop'
          );
          this.cargaladata.dealershipData = data.filter(
            (item) => item.type === 'dealership'
          );
          resolve();
        },
        error: (error) => {
          reject(error); // Rechaza la promesa en caso de error
        },
      });
    });
  }
  fetchData3(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.brandService.getData().subscribe({
        next: (data) => {
          this.cargaladata.brands = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData4(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.documentTypeService.getSelectType().subscribe({
        next: (data) => {
          this.cargaladata.selectTypes = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData5(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userService.getUserAgent().subscribe({
        next: (data) => {
          this.cargaladata.agentData = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData6(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.clientsService.getData().subscribe({
        next: (data) => {
          const updatedResponse = data.data.map((client: any) => {
            return {
              ...client,
              fullname: `${client.name}${
                client.lastname ? ` ${client.lastname}` : ''
              }`.trim(),
            };
          });
          this.cargaladata.clientData = updatedResponse;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  fetchData7(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getStages().subscribe({
        next: (data) => {
          this.cargaladata.stages = data.data;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
  fetchData8(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.orderService.getDeliveryData().subscribe({
        next: (data) => {
          this.cargaladata.delivery_place = data.data.places;
          this.cargaladata.delivery_status = data.data.status;
          resolve();
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
